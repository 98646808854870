import { unstable_getServerSession } from "next-auth/next";
import { authOptions } from "/pages/api/auth/[...nextauth]";
import { frontendName } from "/lib/publicEnv";
import { FaExclamationTriangle, FaExternalLinkAlt } from "react-icons/fa";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

const errors = {
    "default": "An unknown error occurred.",
    "AccessDenied": "Your account is not authorized to access " + frontendName + "." + " Please contact the administrator for more information.",

};

export default function Home(properties) {
    const [barOpen, setBarOpen] = useState(false);
    const router = useRouter();
    useEffect(() => {
        if (router.isReady && router.query.error) {
            const timeout = setTimeout(() => {
                setBarOpen(true);
            }, 250);

            return () => clearTimeout(timeout);
        } else if (router.isReady && !router.query.error) {
            const timeout = setTimeout(() => {
                setBarOpen(false);
            }, 250);

            return () => clearTimeout(timeout);
        };
    }, [router.isReady, router.query.error]);
    
    return (
        <main className="flex flex-col min-h-screen">
            <Transition
                    show={barOpen}
                    enter="transition ease duration-500 transform"
                    enterFrom="opacity-0 -translate-y-12"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease duration-300 transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-12"
                >
                    <div className="pb-8 ">
                        <div className="md:rounded-tl-lg w-full bg-red-700 flex flex-col py-4 items-center justify-center">
                            <div className="flex flex-row gap-x-4 justify-center items-center text-center">
                                <FaExclamationTriangle className="hidden lg:inline-block text-2xl text-white" />
                                <h1 className="text-white font-BreezeAltHeader">
                                    {router.query.error ? errors[errors[router.query.error] ? router.query.error : "default"] : "An unknown error occurred."}
                                </h1>
                            </div>
                        </div>
                    </div>
            </Transition>
            <div className="flex flex-col gap-y-8 pb-12 md:py-12">
                <div className="flex flex-col w-full h-full items-center justify-center gap-y-2">
                    <h1 className="text-4xl text-left">
                        You aren&apos;t signed in.
                    </h1>
                    <h2 className="text-xl font-medium text-left">
                        Please sign in to access {frontendName}.
                    </h2>
                    <button onClick={() => signIn("auth0")} className="px-6 py-2 bg-cyan-300 hover:bg-cyan-400 text-black rounded-2xl flex flex-row gap-x-4 items-center text-center text-xl font-BreezeAltHeader transition-all ease-in-out duration-200">
                        Sign in
                    </button>
                </div>
            </div>
        </main>
    );
};

export async function getServerSideProps(ctx) {
    const session = await unstable_getServerSession(ctx.req, ctx.res, authOptions);

    if (session) {
        return {
            redirect: {
                destination: "/",
                permanent: false
            }
        }
    } else {
        return {
            props: {
                session
            }
        };
    };
};